import { FormattedMessage } from 'react-intl';
import { NavLink as Link, useLocation } from 'react-router-dom';

import { testId } from 'utils/test-id-generator.utils';

import { useFeatures } from 'services/feature-flags/hooks';

import { Routes } from 'pages/routes.constants';

import NewChip from 'components/@common/NewChip';
import { isActiveNavItem, isMenuItemAccessible } from 'components/@navigation/utils';

import { WebConfig } from './config';
import { Nav, NavItem, Wrap } from './Secondary.style';

const Secondary = () => {
  const location = useLocation();
  const features = useFeatures();

  // TODO: This is a bit of an ugly beast, not sure how I can figure out the sub items
  let mainNavKey: string | null = null;

  if (location.pathname.startsWith(Routes.Explore) || location.pathname === '/') {
    mainNavKey = 'explore';
  } else if (location.pathname.startsWith(Routes.Publish)) {
    mainNavKey = 'publish';
  } else if (location.pathname.startsWith(Routes.Analysis)) {
    mainNavKey = 'analysis';
  } else if (location.pathname.startsWith('/belgashare')) {
    mainNavKey = 'belga_share';
  }
  const menus = WebConfig.find(({ name }) => name === mainNavKey)?.children.filter(item =>
    isMenuItemAccessible({ features, item, isPrimaryMenu: false }),
  );

  if (!menus || menus.length <= 1) return null;

  return (
    <Wrap container alignItems="center" justifyContent="center">
      <Nav>
        {menus.map(item => (
          <NavItem
            {...testId('navigation', item.name)}
            key={item.name}
            to={item.route}
            component={Link}
            isActive={() => isActiveNavItem(location.pathname, item, { exact: false })}
            sx={{ width: 'auto', gap: 1 }}
          >
            <FormattedMessage id={item.key} />
            {item.isNew && <NewChip />}
          </NavItem>
        ))}
      </Nav>
    </Wrap>
  );
};

export default Secondary;
