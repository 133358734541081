import { useTheme } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { useFeatures } from 'services/feature-flags/hooks';

import { isActiveNavItem, isMenuItemAccessible } from 'components/@navigation/utils';

import { MobileConfig } from './config';
import { IconStyled, Item } from './Mobile.style';

interface Props {
  isMoreOpen: boolean;
  children?: React.ReactNode;
  onClose(): void;
}

const Mobile = ({ isMoreOpen, onClose, children }: Props) => {
  const location = useLocation();
  const features = useFeatures();
  const theme = useTheme();

  const menus = MobileConfig.filter(item =>
    isMenuItemAccessible({ features, item, isPrimaryMenu: false }),
  );

  return (
    <>
      {menus.map(item => {
        const isActive = !isMoreOpen && isActiveNavItem(location.pathname, item);
        return (
          <Item
            onClick={() => onClose()}
            key={item.name}
            showLabel
            component={Link}
            to={item.route}
            isActive={isActive}
            label={<FormattedMessage id={item.key} />}
            icon={
              <>
                {item.icon && (
                  <IconStyled
                    name={item.icon}
                    color={isActive ? theme.palette.global01[400] : theme.palette.neutral01[300]}
                  />
                )}
              </>
            }
          />
        );
      })}
      {children}
    </>
  );
};

export default Mobile;
