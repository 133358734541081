import { matchPath, RouteProps } from 'react-router-dom';

import FeatureFlagService from 'services/feature-flags/FeatureFlagService';
import { UserFeatureFlags } from 'types/user.types';

import { Routes } from 'pages/routes.constants';

interface MenuAccessibleProps {
  features: FeatureFlagService;
  item: { userFeatureFlag: UserFeatureFlags; isOptional?: boolean };
  isPrimaryMenu: boolean; // true if the menu is in the top navigation bar (Explore, Publish, Analyse, Share)
}

export const isMenuItemAccessible = ({ features, item, isPrimaryMenu }: MenuAccessibleProps) => {
  const hasFeature = !item.userFeatureFlag || features.isEnabled(item.userFeatureFlag);

  // If the menu item is optional, it will be displayed in the menu only if there are other children in the same menu that are accessible
  if (isPrimaryMenu) {
    return !item.isOptional && hasFeature;
  } else {
    return hasFeature;
  }
};

export const isActiveNavItem = (
  location: string,
  item: { route: Routes; aliasRoutes?: Routes[] },
  props: RouteProps = { exact: true },
) => {
  return !!matchPath(location, {
    path: item.aliasRoutes ? [item.route, ...item.aliasRoutes] : item.route,
    ...props,
  });
};
