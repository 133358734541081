import { Grid, List, ListItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { NavLink, useLocation } from 'react-router-dom';

import { testId } from 'utils/test-id-generator.utils';

import { useFeatures } from 'services/feature-flags/hooks';

import NewChip from 'components/@common/NewChip';
import { isActiveNavItem, isMenuItemAccessible } from 'components/@navigation/utils';

import { WebConfig } from './config';

const Main = () => {
  const location = useLocation();
  const features = useFeatures();

  const primaryNavigationItems = WebConfig.filter(({ children }) =>
    children.some(item => isMenuItemAccessible({ features, item, isPrimaryMenu: true })),
  );

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
    >
      <List
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: 0,
          margin: 0,
        }}
      >
        {primaryNavigationItems.map(item => {
          const firstAccessibleSecondaryMenu = item.children.find(item =>
            isMenuItemAccessible({ features, item, isPrimaryMenu: false }),
          );

          return (
            firstAccessibleSecondaryMenu && (
              <ListItem
                {...testId('navigation', item.name)}
                key={item.key}
                to={firstAccessibleSecondaryMenu.route}
                component={NavLink}
                isActive={() => isActiveNavItem(location.pathname, item, { exact: false })}
                sx={{
                  color: 'neutral01.300',
                  lineHeight: '21px',
                  fontWeight: 600,
                  padding: '18px 32px 17px 32px',
                  '&:hover': {
                    color: 'neutral01.200',
                  },

                  '&.active': {
                    color: 'global01.400',
                  },
                }}
              >
                <FormattedMessage id={item.key} />
                {item.isNew && <NewChip sx={{ ml: 1 }} />}
              </ListItem>
            )
          );
        })}
      </List>
    </Grid>
  );
};

export default Main;
