import { removeEmptyValuesFromParams } from 'services/api/utils/remove-empty-values';
import { ApiResponse } from 'types/api.types';
import { Publisher } from 'types/publisher.types';
import {
  BelgaSource,
  Source,
  SourceEditionsParams,
  SourceGroup,
  SourceSearchParams,
  Subsource,
} from 'types/source.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export function fetchSourceGroups(this: RestApiService) {
  return this.api.get<ApiResponse<SourceGroup[]>>('/sourcegroups');
}

export function fetchSources(this: RestApiService, params?: SourceSearchParams) {
  const cleanParams = removeEmptyValuesFromParams(params);

  return this.api.get<ApiResponse<Source[]>>('/sources', { params: cleanParams });
}

export function fetchSource(this: RestApiService, sourceId: Source['id']) {
  return this.api.get<Source>(`/sources/${sourceId}`);
}

export function fetchSubsources(this: RestApiService, sourceId: Source['id']) {
  return this.api.get<ApiResponse<Subsource[]>>(`/sources/${sourceId}/subsources`);
}

export function fetchSourceEditions(
  this: RestApiService,
  userId: User['id'],
  sourceId: Source['id'],
  params?: SourceEditionsParams,
) {
  return this.api.get<ApiResponse<string[]>>(
    `/users/${userId}/kiosk/sources/${sourceId}/editions`,
    { params },
  );
}

export function fetchAllSourcesEditions(this: RestApiService, sourceId?: Source['id']) {
  return this.api.get<ApiResponse<{ sourceId: number; edition: string }[]>>(`/sources/editions`, {
    params: { sourceId },
  });
}

export function addFavoriteSource(
  this: RestApiService,
  userId: User['id'],
  sourceId: Source['id'],
) {
  return this.api.post<{ sourceId: Source['id'] }>(`/users/${userId}/favoritesources`, {
    sourceId,
  });
}

export function deleteFavoriteSource(
  this: RestApiService,
  userId: User['id'],
  sourceId: Source['id'],
) {
  return this.api.delete(`/users/${userId}/favoritesources/${sourceId}`);
}

export function fetchBelgaSources(this: RestApiService) {
  return this.api.get<BelgaSource[]>(`/sources/belga`);
}

/**
 * @deprecated
 * This endpoint is used to fetch sources for a list of publishers (even if the user doesn't have access to them)
 * In time this should be replaced with /sources -> which now returns only sources that the current user has access to
 * The /sources endpint should change into a user specific endpoint /users/:userId/sources and return only sources that the user has access to
 */
export function fetchPublishersSources(this: RestApiService, publisherIds: Publisher['id'][]) {
  return this.api.get<ApiResponse<Source[]>>('/publisher-sources', { params: { publisherIds } });
}
