export enum Routes {
  Root = '/',
  Explore = '/explore',

  KioskFrontPage = '/explore/kiosk',
  // Displays a single shared article on the front page
  KioskShared = '/explore/kiosk/article/shared/:newsObjectUuid',
  KioskBelgaNow = '/explore/kiosk/belga-now',
  KioskFeedOverview = '/explore/kiosk/live-feeds',
  KioskFeedDetail = '/explore/kiosk/live-feeds/:feedId',
  KioskFeedDetailArticle = '/explore/kiosk/live-feeds/:feedId/article/:newsObjectUuid',
  KioskPublications = '/explore/kiosk/publications',
  KioskPublicationsSearch = '/explore/kiosk/publications/latest/:mediumType',
  KioskPublicationsDetail = '/explore/kiosk/publications/:deliverableId/:sourceId',
  KioskRealtime = '/explore/kiosk/realtime',
  KioskNewsbrands = '/explore/kiosk/newsbrands',
  KioskNewsbrandsDetail = '/explore/kiosk/newsbrands/:sourceId',
  KioskRecommended = '/explore/kiosk/recommended',
  KioskRecommendedDetail = '/explore/kiosk/recommended/:collectionId',
  KioskNewsletters = '/explore/kiosk/newsletters',
  KioskPressReleases = '/explore/kiosk/press-releases',
  KioskNewslettersDetail = '/explore/kiosk/newsletters/:newsletterId',
  KioskNewslettersDetailSearch = '/explore/kiosk/newsletters/:newsletterId/search',
  KioskReports = '/explore/kiosk/reports',
  KioskReportsDetail = '/explore/kiosk/reports/:newsletterId',
  KioskAgenda = '/explore/kiosk/agenda',
  KioskNewsrooms = '/explore/kiosk/newsrooms',
  KioskNewsroomsDetail = '/explore/kiosk/newsrooms/:newsroomId',
  KioskNewsroomEventDetail = '/explore/kiosk/newsrooms/:newsroomId/events/:eventId',
  KioskPressReleaseDetail = '/explore/kiosk/newsrooms/:newsroomId/press-releases/:pressReleaseId',
  KioskBelgaImage = '/explore/kiosk/belga-image',
  KioskTopicsDetail = '/explore/kiosk/topics/:topicId',
  KioskTopicsDetailArticle = '/explore/kiosk/topics/:topicId/article/:newsObjectUuid',
  KioskTopicsOverview = '/explore/kiosk/topics',
  KioskJournalistsDetail = '/explore/kiosk/journalists/:journalistId',

  // Old route, keeping it alive because of old emails
  KioskNewslettersDetailShared = '/explore/kiosk/newsletters/:newsletterId/shared/:newsObjectUuid',
  // Old route, keeping it alive because of old emails
  KioskNewslettersDetailArticle = '/explore/kiosk/newsletters/:newsletterId/article/:newsObjectUuid',
  KioskNewslettersDetailLabelSummaryArticle = '/explore/kiosk/newsletters/:newsletterId/items/:itemId/article/:newsObjectUuid',

  // This links to old gopress pressreviews
  KioskRecommendedOldPressReviews = '/explore/kiosk/recommended/pressreviews/:pressReviewId',
  KioskGopressArchive = '/explore/kiosk/gopress-archive',
  KioskGopressArchiveDetail = '/explore/kiosk/gopress-archive/folders/:folderId',

  Boards = '/explore/boards',
  BoardsDetail = '/explore/boards/:boardId',
  BoardsAllContent = '/explore/boards/:boardId/all-content',
  BoardsAllContentArticle = '/explore/boards/:boardId/all-content/article/:newsObjectUuid',
  BoardsBelga = '/explore/boards/:boardId/belga',

  Labels = '/explore/labels',
  LabelsDetail = '/explore/labels/:labelId',

  Documents = '/explore/documents',

  Print = '/print',
  Search = '/search',
  // Displays multiple shared articles in a list
  SharedArticles = '/articles/shared',
  SavedSearchDetail = '/search/saved-searches/:savedSearchId',
  SavedSearchesDetailArticle = '/search/saved-searches/:savedSearchId/article/:newsObjectUuid',
  RecurringNewsletterLatestEdition = '/newsletters/latest/:recurringId',
  RecurringReportLatestEdition = '/reports/latest/:recurringId',

  // Publish
  Publish = '/publish',
  Feeds = '/publish/feeds',
  FeedsDetail = '/publish/feeds/:feedId',
  Newsletters = '/publish/newsletters',
  NewsletterDetail = '/publish/newsletters/:newsletterId',
  Reports = '/publish/reports',
  ReportDetail = '/publish/reports/:newsletterId',

  // Contacts (publish)
  ContactsPublish = '/publish/contacts',
  ContactGroupsPublish = '/publish/contacts/groups',
  ContactGroupsDetailPublish = '/publish/contacts/groups/:contactGroupId',
  ContactTagsPublish = '/publish/contacts/tags',

  // Contacts (share)
  ContactsShare = '/belgashare/contacts',
  ContactGroupsShare = '/belgashare/contacts/groups',
  ContactGroupsDetailShare = '/belgashare/contacts/groups/:contactGroupId',
  ContactTagsShare = '/belgashare/contacts/tags',

  // Share
  BelgaShare = '/belgashare',
  Newsrooms = '/belgashare/newsrooms',
  NewsroomsDetail = '/belgashare/newsrooms/:newsroomId',
  NewsroomsInsights = '/belgashare/newsrooms/:newsroomId/insights',
  NewsroomsInsightsPressReleases = '/belgashare/newsrooms/:newsroomId/insights/press-releases',
  NewsroomsInsightsPressReleaseDetail = '/belgashare/newsrooms/:newsroomId/insights/press-releases/:pressReleaseId',
  NewsroomsPressReleases = '/belgashare/newsrooms/:newsroomId/press-releases',
  NewsroomsPressReleasesDetail = '/belgashare/newsrooms/:newsroomId/press-releases/:pressReleaseId',
  NewsroomsPressReleasesDetailStep = '/belgashare/newsrooms/:newsroomId/press-releases/:pressReleaseId/:step',
  Journalist = '/belgashare/journalist',
  JournalistDetail = '/belgashare/journalist/:journalistId',

  // Analysis
  Analysis = '/analysis',
  ExploratoryAnalysis = '/analysis/exploratory',
  SavedAnalyses = '/analysis/saved',
  SavedAnalysisDetail = '/analysis/saved/:analysisId',

  // Settings
  Settings = '/settings',
  SettingsGeneral = '/settings/general',
  SettingsNotifications = '/settings/notifications',
  SettingsKeywords = '/settings/keywords',
  SettingsTemplates = '/settings/templates',
  SettingsTemplatesDetail = '/settings/templates/:templateId',
  SettingsBrands = '/settings/brands',
  SettingsRecurringTemplates = '/settings/recurring-templates',
  SettingsRecurringTemplatesDetail = '/settings/recurring-templates/:recurringId',
  SettingsPurchasedArticles = '/settings/purchased-articles',
  SettingsActivityReports = '/settings/activity-reports',
  SettingsUsers = '/settings/users',
  SettingsUsersDetail = '/settings/users/:userId',
  SavedItems = '/saved-items',
  SettingsSubscriptions = '/settings/subscriptions',
  SettingsNewsletterStatistics = '/settings/newsletter-statistics',
  // TODO: Remove these routes after a while - Added on 11/24
  SettingsContactsInfo = '/settings/contacts-info',
  SettingsContactGroupsInfo = '/settings/contact-groups-info',

  // Unauthenticated public routes
  Share = '/share/:newsObjectUuidList',

  // generic routes
  Maintenance = '/maintenance',
  AccountDisabled = '/account-disabled',
  RedirectNotAuthorized = '/not-allowed',
  NotFound = '/not-found',
  Error = '/error',
  SessionHijacked = '/reauthentication',
  Unsubscribe = '/unsubscribe/:hash',
  Register = '/register',
}
