import { AxiosResponse } from 'axios';
import qs from 'query-string';

import { ApiResponse } from 'types/api.types';
import { PortfolioItem } from 'types/journalist-portfolio.types';
import {
  Journalist,
  JournalistProfileCreateParams,
  JournalistProfilesSearchParams,
  JournalistProfileUpdateParams,
} from 'types/journalist-profiles.types';
import { Media } from 'types/media.types';
import { NewsObject } from 'types/newsobject.types';
import { PressCoveragesSearchParams } from 'types/newsroom.types';
import { User } from 'types/user.types';

import RestApiService from '.';
import { removeEmptyValuesFromParams } from './utils/remove-empty-values';

export function fetchUserJournalistProfile(this: RestApiService, userId: User['id']) {
  return this.api.get<Journalist>(`/users/${userId}/journalist`);
}
export function fetchJournalistById(this: RestApiService, journalistId: Journalist['id']) {
  return this.api.get<Journalist>(`/journalists/${journalistId}`);
}

export function fetchJournalists(
  this: RestApiService,
  dirtyParams: JournalistProfilesSearchParams,
) {
  return this.api.get<ApiResponse<Journalist[]>>(`/journalists`, {
    params: removeEmptyValuesFromParams(dirtyParams),
    paramsSerializer: p => qs.stringify(p, { arrayFormat: 'comma' }),
  });
}

export function updateJournalistProfile(
  this: RestApiService,
  journalistId: Journalist['id'],
  params: JournalistProfileUpdateParams,
) {
  return this.api.put<JournalistProfileUpdateParams, AxiosResponse<Journalist>>(
    `/journalists/${journalistId}`,
    params,
  );
}

export function createJournalistProfile(
  this: RestApiService,
  params: JournalistProfileCreateParams,
) {
  return this.api.post<JournalistProfileCreateParams, AxiosResponse<Journalist>>(
    `/journalists`,
    params,
  );
}

export function deleteJournalistProfile(this: RestApiService, journalistId: Journalist['id']) {
  return this.api.delete(`/journalists/${journalistId}`);
}

export function restoreJournalistCoverImage(this: RestApiService, journalistId: Journalist['id']) {
  return this.api.put<Media>(`/journalists/${journalistId}/cover/reset`);
}

export function fetchJournalistPortfolioItems(
  this: RestApiService,
  journalistId: Journalist['id'],
  dirtyParams: PressCoveragesSearchParams,
) {
  const params = removeEmptyValuesFromParams(dirtyParams);

  return this.api.get<ApiResponse<PortfolioItem[]>>(`/journalists/${journalistId}/portfolio`, {
    params,
  });
}

export function updatePortfolioItems(
  this: RestApiService,
  journalistId: Journalist['id'],
  portfolioItems: { id: PortfolioItem['id']; order: number }[],
) {
  return this.api.put<string[], AxiosResponse<NewsObject>>(
    `/journalists/${journalistId}/portfolio`,
    portfolioItems,
  );
}

export function removePortfolioItems(
  this: RestApiService,
  journalistId: Journalist['id'],
  portfolioIds: PortfolioItem['id'][],
) {
  return this.api.delete(`/journalists/${journalistId}/portfolio`, { data: portfolioIds });
}

export function addPortfolioItems(
  this: RestApiService,
  journalistId: Journalist['id'],
  newsObjectUuids: NewsObject['uuid'][],
) {
  return this.api.post<string[], AxiosResponse<NewsObject>>(
    `/journalists/${journalistId}/portfolio/newsuuid`,
    newsObjectUuids,
  );
}
