import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import config from 'config';

import { BelgaNowSourceOption } from 'types/belga-now.types';
import { Topic } from 'types/topic.types';
import { UserLanguages } from 'types/user.types';

import { Option } from 'components/@filters/DropdownFilter';

export type BelgaNowFiltersState = {
  topics?: Option<Topic['id']>[];
  languages?: Option<UserLanguages>[];
  sources?: BelgaNowSourceOption[];
};

const INITIAL_STATE: BelgaNowFiltersState = {};

export const belgaNowFiltersPersistConfig = {
  key: `${config.LOCAL_STORAGE_ID}.belga-now-filters`,
  version: 1,
  storage,
};

const belgaNowFiltersSlice = createSlice({
  name: 'belgaNowFilters',
  initialState: INITIAL_STATE,
  reducers: {
    SET_FILTERS: (state, action: PayloadAction<BelgaNowFiltersState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { actions: belgaNowActions } = belgaNowFiltersSlice;

export default belgaNowFiltersSlice.reducer;
